<template>
  <div class="conten">
    <div class="top">
      <h3>基础档案明细</h3>
    </div>
    <div class="comprehensive-table-container">
      <vab-query-form>
        <vab-query-form-left-panel style="float: left; margin-top: 20px">
          <el-button
            type="primary"
            @click="addOrEditDetails"
          >
            新增
          </el-button>
          <el-button
            type="warning"
            @click="deleteData"
          >
            删除
          </el-button>
        </vab-query-form-left-panel>
      </vab-query-form>

      <el-table
        ref="tableSort"
        v-loading="listLoading"
        border
        :data="list"
        element-loading-text="拼命加载中"
        :height="height"
        :row-style="{height:'30px'}"
        :cell-style="{padding:'2px'}"
        default-expand-all
        @selection-change="setSelectRows"
      >
      >
      <el-table-column
        align="center"
        show-overflow-tooltip
        type="selection"
        width="55"
      />
        <el-table-column
          align="left"
          label="申报中文品名"
          prop="reportCnName"
          show-overflow-tooltip
          width="180"
        />

        <el-table-column
          align="left"
          label="申报英文品名"
          prop="reportEnName"
          show-overflow-tooltip
          width="180"
        />

        <el-table-column
          align="left"
          label="中国海关编码"
          prop="hsCode"
          show-overflow-tooltip
          width="140"
        />

        <el-table-column
          align="left"
          label="中国申报要素"
          prop="reportFactor"
          show-overflow-tooltip
          width="180"
        />

        <el-table-column
          align="left"
          label="中文材质"
          prop="materialCn"
          show-overflow-tooltip
          width="140"
        />

        <el-table-column
          align="left"
          label="英文材质"
          prop="materialEn"
          show-overflow-tooltip
          width="180"
        />

        <el-table-column
          align="left"
          label="用途"
          prop="useTo"
          show-overflow-tooltip
          width="180"
        />

        <el-table-column
          align="left"
          label="进口国家"
          prop="importCountry"
          show-overflow-tooltip
          width="120"
        />

        <el-table-column
          align="left"
          label="进口海关编码"
          prop="importHsCode"
          show-overflow-tooltip
          width="120"
        />

        <el-table-column
          align="left"
          label="关税税率"
          prop="rateOfDuty"
          show-overflow-tooltip
          width="120"
          v-if="$roles.roles[0]=='admin'"
        />

        <el-table-column
          align="left"
          label="认证要求"
          prop="certification"
          show-overflow-tooltip
          width="80"
        />

        <el-table-column
          align="left"
          label="风险评估"
          prop="riskTypeName"
          show-overflow-tooltip
          width="100"
        />

        <el-table-column
          align="left"
          label="是否可承接"
          prop="acceptabilityName"
          show-overflow-tooltip
          width="100"
        />

        <el-table-column
          align="left"
          label="包税附加费"
          prop="surcharge"
          show-overflow-tooltip
          width="100"
        />

        <el-table-column
          align="left"
          label="创建人"
          prop="createBy"
          show-overflow-tooltip
          width="100"
        />

        <el-table-column
          align="left"
          label="创建时间"
          prop="createTime"
          show-overflow-tooltip
          width="160"
        />
        <el-table-column
          align="center"
          label="操作"
          show-overflow-tooltip
          width="130"
          fixed="right"
          v-if="$roles.roles[0]=='admin'"
        >
          <template #default="{ row }">
            <el-button type="text" @click="addOrEditDetails(row)">编辑</el-button>
          </template>
        </el-table-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
      <DetailsAdd
        ref="addOrEditDetails"
        :fileId="fileId"
        :Modulelist="detailsList"
      />
    </div>
  </div>
</template>

<script>
import DetailsAdd from './detailsAdd'
import {
  detailsBasicFiles,
  deleteDetails,
} from '@/api/cmsApi'
export default {
  name: 'details',
  components: {
    DetailsAdd,
  },

  props: {
    title: {
      type: String,
    },
    queryList: Function,
    isMenuManageShow: Boolean,
    id: {
      type: Number,
    },
  },
  data() {
    return {
      idList:[],
      fileId:"",
      height: this.$baseTableHeight(3) + 100,
      list: [],
      disabled: true,
      listLoading: false,
      optionsOne: [],
      options: [
        {
          value: '1',
          label: '正常',
        },
        {
          value: '2',
          label: '停用',
        },
      ],
      queryForm: {
        id: '',
      },
      pickerOptions: {
        disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000
        },
      },
    }
  },
  watch: {
    visible(val) {
      this.dialog_visible = val
    },
  },
  created() {
    this.detailsList()
  },
  methods: {
    reset() {
      this.$emit('dialogVisibleEvent', false)
    },
    async detailsList() {
      this.fileId = this.id
      this.queryForm.id = this.id
      const res = await detailsBasicFiles(this.queryForm)
      this.list = res.data
    },
    setSelectRows(val) {
      this.selectRows = val
      if (this.selectRows.length > 0) {
        this.selectRows.map((item) => this.idList.push(item.id))
        this.queryForm.idList = this.idList
      }else{
        this.idList = []
        this.queryForm.idList = []
      }
    },
    deleteData(){
      let data = {
          idList: this.idList,
        }
      this.$baseConfirm(
          '你确定全部【删除】勾选数据吗？',
          null,
          async () => {
            const res = await deleteDetails(data)
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            await this.detailsList()
          }
        )
    },
    //新增、编辑
    addOrEditDetails(row) {
      this.$refs['addOrEditDetails'].showEdit(row)
    },
    //删除
    async handleDelete(row) {
      const form = {
        dataGroupGuid: row.dataGroupGuid,
      }
      this.$baseConfirm('你确定要删除当前项吗', '警告', async () => {
        const res = await deleteByGroupGuid(form)
        if (res.code == '200') {
          this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
          await this.userDefinedPermissionList()
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.conten {
  width: 100%;
  padding: 15px;
  background: #fff;
  height: 100%;
  h3 {
    margin: 0px;
  }
  .conten-from {
    width: 100%;
    padding-top: 50px;
  }
  .bottom {
    margin-left: 90px;
  }
}
.comprehensive-table-container {
  position: relative;
}
::v-deep .left-panel {
  margin: 0px !important;
}
</style>