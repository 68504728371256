var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        "append-to-body": "",
        width: "400px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "120px", model: _vm.form, rules: _vm.rules }
        },
        [
          _c(
            "vab-query-form",
            [
              _c(
                "vab-query-form-top-panel",
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "进口国家:", prop: "importCountry" }
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "200", "show-word-limit": "" },
                        model: {
                          value: _vm.form.importCountry,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "importCountry",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.importCountry"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "进口海关编码:", prop: "importHsCode" }
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "200", "show-word-limit": "" },
                        model: {
                          value: _vm.form.importHsCode,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "importHsCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.importHsCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "关税税率:", prop: "rateOfDuty" }
                    },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "180px" },
                        attrs: {
                          size: "medium",
                          precision: 2,
                          step: 0.1,
                          max: 100,
                          min: 0
                        },
                        model: {
                          value: _vm.form.rateOfDuty,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "rateOfDuty", $$v)
                          },
                          expression: "form.rateOfDuty"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "风险评估:", prop: "riskType" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择类型" },
                          model: {
                            value: _vm.form.riskType,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "riskType", $$v)
                            },
                            expression: "form.riskType"
                          }
                        },
                        _vm._l(_vm.riskTypeList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "是否可承接:", prop: "acceptability" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择类型" },
                          model: {
                            value: _vm.form.acceptability,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "acceptability", $$v)
                            },
                            expression: "form.acceptability"
                          }
                        },
                        _vm._l(_vm.acceptabilityList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "包税附加费:", prop: "surcharge" }
                    },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "180px" },
                        attrs: {
                          size: "medium",
                          precision: 2,
                          step: 0.1,
                          max: 100,
                          min: 0
                        },
                        model: {
                          value: _vm.form.surcharge,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "surcharge", $$v)
                          },
                          expression: "form.surcharge"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { label: "认证要求:", prop: "certification" }
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "200", "show-word-limit": "" },
                        model: {
                          value: _vm.form.certification,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.form,
                              "certification",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "form.certification"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }